import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';
import TestimonialLogo from '../Image/TestimonialLogo';
import NeonArrowTestimonial from '../Image/NeonArrow';
import styled from 'styled-components';
import { MDBContainer, MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';

const StyledCard = styled(MDBCard)`
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    height: 520px;
  }
  @media (max-width: 1024px) {
    height: 600px;
  }
  @media (max-width: 768px) {
    height: 100%;
  }
`;
const LogoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  margin-left: ${(props) => (props.marginleft ? '30px' : '0px')};
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
    margin-top: ${(props) => (props.margintop ? '20px' : 0)};
  }
`;

const Testimonials = () => {
  return (
    <section id="testimonials">
      <Container>
        <Title color title="What our clients say" />
        <Fade bottom duration={1000} delay={800} distance="30px">
          <MDBContainer>
            <section className="my-5 text-center">
              <StyledRow>
                <Column>
                  <StyledCard testimonial className="md-shadow">
                    <LogoImage>
                      <TestimonialLogo />
                    </LogoImage>
                    <MDBCardBody style={{ marginTop: '15px' }}>
                      <h4 className="mb-4 font-weight-bold">Jacob W.</h4>
                      <hr />
                      <p className="mt-4 dark-grey-text">
                        <MDBIcon icon="quote-left" className="pr-2" />
                        Cory has been managing my facebook ads since the beginning of this year, and
                        we've noticed a steady and significant increase of online sales and traffic
                        to the website. Cory is professional, easy to work with, and knows what he's
                        doing. If you're trying to scale your business, I'd highly recommend using
                        him!
                        <MDBIcon icon="quote-right" className="pr-2" />
                      </p>
                    </MDBCardBody>
                  </StyledCard>
                </Column>

                <Column margintop marginleft>
                  <StyledCard testimonial className="md-shadow">
                    <LogoImage>
                      <NeonArrowTestimonial />
                    </LogoImage>
                    <MDBCardBody style={{ marginTop: '15px' }}>
                      <h4 className="mb-4 font-weight-bold">Nicole P.</h4>
                      <hr />
                      <p className="mt-4 dark-grey-text">
                        <MDBIcon icon="quote-left" className="pr-2" />
                        Brooke is amazing to work with! I love that she helps me with brainstorming
                        new ideas to gain new customers. I can easily get in touch with her via text
                        or email with a speedy reply. She does weekly video recaps that keep me in
                        the loop with my account too. I would highly recommend Iffert Media for IG,
                        FB, TikTok ads and Email Marketing!
                        <MDBIcon icon="quote-right" className="pr-2" />
                      </p>
                    </MDBCardBody>
                  </StyledCard>
                </Column>
              </StyledRow>
            </section>
          </MDBContainer>
        </Fade>
      </Container>
    </section>
  );
};

export default Testimonials;
