import React from 'react';
import { Helmet } from 'react-helmet';
import { LocalBusinessJsonLd, SocialProfileJsonLd } from 'gatsby-plugin-next-seo';
import App from '../components/App';
import SEO from '../components/SEO/SEO';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  const { title, image, lang, description, siteUrl } = headData;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
      />
      <SEO title={title} lang={lang || 'en'} image={image} description={description} />
      <LocalBusinessJsonLd
        type="Professional Services"
        id="https://www.iffertmedia.com/"
        name="Iffert Media | Social Media & Advertising Services"
        description={description}
        url="https://www.iffertmedia.com/"
        siteUrl={siteUrl}
        telephone="+14088717984"
        address={{
          addressLocality: 'Nashville',
          addressRegion: 'TN',
          addressCountry: 'US',
        }}
        images={[
          'https://www.iffertmedia.com/static/ee7180b9430b423ddc2fdbdfe2f76f3f/0dadc/IFFERT-MEDIA.png',
        ]}
      />
      <SocialProfileJsonLd
        type="Local Business"
        name="Iffert Media"
        url="https://www.iffertmedia.com/"
        sameAs={['https://www.facebook.com/iffertmedia/', 'https://www.instagram.com/iffertmedia/']}
      />
      <App />
    </>
  );
};
// <GatsbySeo
//         title="Iffert Media | Social Media Marketing & Advertising"
//         description="Iffert Media is a social media marketing agency in Nashville working with clients nationwide! Let's grow your business together."
//         canonical="https://www.iffertmedia.com/"
//         openGraph={{
//           url: 'https://www.iffertmedia.com',
//           title: 'Iffert Media | Social Media Marketing & Advertising',
//           description:
//             "Iffert Media is a social media marketing agency in Nashville working with clients nationwide! Let's grow your business together.",
//           images: [
//             {
//               url:
//                 'https://www.iffertmedia.com/static/ee7180b9430b423ddc2fdbdfe2f76f3f/0dadc/IFFERT-MEDIA.png',
//               alt: 'Iffert Media',
//             },
//           ],
//           type: 'website',

//           site_name: 'Iffert Media | Social Media Marketing & Advertising',
//         }}
//         twitter={{
//           cardType: 'summary_large_image',
//           title: 'Iffert Media | Social Media Marketing & Advertising',
//           site: 'https://www.iffertmedia.com',
//           images: [
//             {
//               url:
//                 'https://www.iffertmedia.com/static/ee7180b9430b423ddc2fdbdfe2f76f3f/0dadc/IFFERT-MEDIA.png',
//               alt: 'Iffert Media',
//             },
//           ],
//           description:
//             "Iffert Media is a social media marketing agency in Nashville working with clients nationwide! Let's grow your business together.",
//         }}
//       />
