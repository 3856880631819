import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import SocialAbout from '../Image/Social';
import styled from 'styled-components';
import PortfolioContext from '../../context/context';
import { Link } from 'gatsby';

const Div = styled.div`
  display: flex;
  justify-content: center;
`;

const MobileTitle = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 767px) {
    display: none;
  }
`;

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade
              bottom
              left={isDesktop}
              bottom={isMobile}
              duration={1000}
              delay={600}
              distance="30px"
            >
              <MobileTitle>
                <Title title="Helping Our Clients Grow" />
              </MobileTitle>
              <div className="about-wrapper__image">
                <AboutImg alt="About Iffert Media" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <Title marginbottom title="WE'RE HERE TO HELP YOU" />
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne ||
                    "Every business wants more leads that convert to sales. But how do you attain that goal? That's where we come in. Iffert Media is a digital marketing agency located in Nashville, TN and was founded to help turn businesses into money making machines with social media marketing and advertising."}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo ||
                    "As an agency, we've worked with solopreneurs like health coaches, wedding professionals, personal trainers, and real estate agents to small-medium businesses like gyms and restaurants. Our portfolio also includes national accounts such as fitness supplements and other e-commerce brands."}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree ||
                    'We create a winning digital marketing plan specific to your business. We then execute the plan to increase ROI and conversion rates, while reaching your business goals.'}
                </p>
              </div>
              <Div>
                <Link
                  to="/services"
                  className="cta-btn cta-btn--contact"
                  style={{ color: '#000', width: '50%' }}
                >
                  Find Out How
                </Link>
              </Div>
            </Fade>
          </Col>
        </Row>
      </Container>
      <div className="social-wrapper">
        <Container>
          <Row className="about-wrapper">
            <Col md={6} sm={12}>
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
                <Title color title="FREE Consultation" />
                <div className="about-wrapper__info">
                  <p className="social-text">
                    We have a strategy that gets you results with a team that's dedicated to raising
                    your ROI.
                  </p>
                  <p className="social-text">
                    Iffert Media is built on trust and relationships, so the next step is to learn
                    about your business! Tell us all about your goals, expectations, successful
                    marketing you've done in the past, and anything else. The more we learn about
                    the business, the better! We take pride in providing marketing help tailored
                    toward your specific needs.
                  </p>
                  <p className="social-text">
                    Apply today for a free 15 minute consultation and marketing strategy below. If
                    not now, when?
                  </p>
                  <Div>
                    <Link to="/contact" className="cta-btn cta-btn--strategy">
                      Schedule Now
                    </Link>
                  </Div>
                </div>
              </Fade>
            </Col>
            <Col md={6} sm={12}>
              <Fade bottom duration={1000} delay={600} distance="30px">
                <div className="about-wrapper__image">
                  <SocialAbout alt="Social Media Marketing" />
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default About;
