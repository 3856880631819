import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  width: 100%;
  align-items: center;
  justify-items: center;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
  // @media (max-width: 750px) {
  //   grid-template-columns: repeat(1, 1fr);
  // }
`;

const Work = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: allFile(
        limit: 12
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "logos/work" }
        }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      mobile: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "logos/work" }
        }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fixed(width: 125) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 750);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 750);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  return (
    <section id="work">
      <Container>
        <Fade bottom duration={1000} delay={800} distance="30px">
          <Title title="Our Great Clients" />
          {isDesktop ? (
            <div>
              <Grid>
                {data.desktop.edges.map((image) => (
                  <hover>
                    <Img fixed={image.node.childImageSharp.fixed} />
                  </hover>
                ))}
              </Grid>
              <div className="testimonials-wrapper">
                <p className="testimonials-wrapper__text"></p>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <Grid>
                  {data.mobile.edges.map((image) => (
                    <Img fixed={image.node.childImageSharp.fixed} />
                  ))}
                </Grid>
                <div className="testimonials-wrapper">
                  <p className="testimonials-wrapper__text"></p>
                </div>
              </div>
            </div>
          )}
        </Fade>
      </Container>
    </section>
  );
};

export default Work;
